export const hotel = {
  hopeSeparateBed: "同一ベッド(ダブルベッド等)不可",
  separateBeds: "一人一台のベッド",
  mealRequest: "食事希望",
  rankSelection: "グレード指定",
  recommendedGenre: "おすすめジャンル",
  viewType: "眺望タイプ",
  bedSizeType: "部屋タイプ",
  bedSizeTypes: "ベッドタイプ",
  bedCount: "ベッド数",
  specificHotel: "特定のホテル",
  selectedHotel: "指定ホテル",
  amenity: "アメニティ",
  smokingTypes: "喫煙 / 禁煙",
  roomAmenities: "部屋の設備",
  hotelAmenities: "ホテル設備",
  categories: "ホテルタイプ",
  spokenLanguages: "ホテル対応言語",
  rank: "ホテルランク",
  minRating: "レビュー",
  existIntroduction: "紹介文",
  mealType: "食事タイプ",
  region: "地域",
  hotelId: "指定ホテル",
  selectionCriteria: "選択基準",
  destination: "目的地",
  schedule: "日程",
  people: "人数",
  destinationOriginal: "目的地",
  scheduleOriginal: "日程",
  peopleOriginal: "人数",
};
