export const flight = {
  operatingAirline: "Operating Airline: {{name}}",
  toAirport: "To {{name}} ",
  departingFlight: "Depart",
  returningFlight: "Return",
  maxDeparture1OvernightNum: "Depart overnight",
  maxLastOvernightNum: "Return overnight",
  useLcc: "Use LCC",
  notUseLcc: "Do not use LCC",
  reservedSeat: "Reserved seat",
  firstDepartureTime: "Departure time",
  lastArrivalTime: "Return time",
  baggageDisplay: "{{bags}} bag(s): {{weight}} ({{price}})",
  additionalBaggage: "Additional baggage",
  addBaggage: "Add baggage",
  freeBaggageType: "Free baggage",
  arrangementType: "Arrangement type",
  specificFlight: "Specific flight",
  overnight: "Overnight",
  overnightOutbound: "Overnight(Outbound)",
  overnightInbound: "Overnight(Inbound)",
  outbound: "Outbound",
  inbound: "Inbound",
  itinerary: "Itinerary{{index}}",
  codeshare: "Code sharing",
  excludeCodeshare: "Exclude code sharing",
  selectedAirline: "Selected airline",
  alliance: "Alliance",
  alliances: "Alliances",
  excludeAirlineCountry: "Exclude specific country airlines",
  excludeAirlineCountries: "Exclude specific country airlines",
  excludeTransitAirport: "Exclude specific transit airports",
  transit: "Transit",
  notChangeAirportWhenTransit:
    "Exclude flights with airport transfers when connecting",
  flightType: "Flight type",
  cabinClass: "Preferred class",
  flightNumberOfAdult: "Adults(12+)",
  flightNumberOfChildren: "Children(0-11)",
  maxStop: "Stop count",
  age: "Age",
  selectionCriteria: "Selection Criteria",
  origin: "Origin",
  destination: "Destination",
  schedule: "Schedule",
  people: "People",
  originOriginal: "Origin",
  destinationOriginal: "Destination",
  scheduleOriginal: "Schedule",
  peopleOriginal: "People",
  airlines: "Airlines",
  airline: "Airline",
  travelPeriod: "Travel period",
  travelDays: "Travel days",
  hotelNight: "Hotel nights",
  startDate: "Start date",
  endDate: "End date",
};
