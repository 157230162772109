export const flight = {
  operatingAirline: "運行会社: {{name}}",
  toAirport: "{{name}}まで",
  departingFlight: "往路",
  returningFlight: "復路",
  maxDeparture1OvernightNum: "往路の機内泊",
  maxLastOvernightNum: "復路の機内泊",
  useLcc: "LCCの利用",
  notUseLcc: "LCCを利用しない",
  reservedSeat: "座席指定",
  firstDepartureTime: "出国時刻",
  lastArrivalTime: "帰国時刻",
  baggageDisplay: "{{bags}}個: {{weight}}まで ({{price}})",
  additionalBaggage: "追加預け入れ手荷物",
  addBaggage: "預け入れ手荷物を追加",
  freeBaggageType: "無料預け入れ手荷物",
  arrangementType: "手配タイプ",
  specificFlight: "特定のフライト",
  overnight: "機内泊",
  overnightOutbound: "機内泊(OUT)",
  overnightInbound: "機内泊(IN)",
  outbound: "往路",
  inbound: "復路",
  codeshare: "コードシェア便",
  itinerary: "経路{{index}}",
  excludeCodeshare: "コードシェア便を除外する",
  selectedAirline: "指定航空会社",
  alliance: "アライアンス",
  alliances: "アライアンス",
  excludeAirlineCountry: "特定国の航空会社の除外",
  excludeAirlineCountries: "特定国の航空会社の除外",
  excludeTransitAirport: "特定乗継空港の除外",
  transit: "乗り継ぎ",
  notChangeAirportWhenTransit: "乗り継ぎ時に空港移動があるフライトを除外する",
  flightType: "フライトタイプ",
  departureDate: "出発日",
  returnDate: "現地出発日",
  cabinClass: "座席クラス",
  flightNumberOfAdult: "大人(12歳以上)",
  flightNumberOfChildren: "子供(0～11歳)",
  maxStop: "経由回数",
  age: "年齢",
  selectionCriteria: "選択基準",
  origin: "出発地",
  destination: "目的地",
  schedule: "日程",
  people: "人数",
  originOriginal: "出発地",
  destinationOriginal: "目的地",
  scheduleOriginal: "日程",
  peopleOriginal: "人数",
  airlines: "航空会社",
  airline: "航空会社",
  travelPeriod: "旅行期間",
  travelDays: "旅行日数",
  hotelNight: "宿泊日数",
  startDate: "開始日",
  endDate: "終了日",
};
