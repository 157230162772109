export const masterMessageGroup = {
  masterMessageGroupList: "Message Group List",
  masterMessageGroupName: "Message group name",
  messageGroup: "Message group",
  groupType: "Group type",
  addMessages: "Add messages",
  operation: "Operation",
  action: "Action",
  carousel: "Carousel",
  actionData: "Action data",
  messagePreview: "Message preview",
};
